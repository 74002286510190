import { Routes, Route, Link, Navigate } from "react-router-dom";


/** ALL ROUTE ELEMENTS */
import AppHome from "pages/AppHome";
import ContactUs from "pages/public/ContactUs";
import Privacy from "pages/public/Privacy";
import Cookies from "pages/public/Cookies";
import TnCs from "pages/public/TnCs";
import Businesses from "pages/public/Businesses";
import Professionals from "pages/public/Professionals";
import Solutions from "pages/public/Solutions";
import Jobs from "pages/public/Jobs";




/** ALL ROUTE ELEMENTS END */


const AllRoutes = () => {
    return ( 
        <>        
            <Routes>
                
                <Route path="/" element={ <AppHome /> } />
                <Route path="/home" element={ <Navigate to='/' /> } />

                <Route path="/businesses" element={ <Businesses /> } />
                <Route path="/business" element={ <Navigate to='/businesses' /> } />

                <Route path="/professionals" element={ <Professionals /> } />
                <Route path="/professional" element={ <Navigate to='/professionals' /> } />

                <Route path="/solutions" element={ <Solutions /> } />
                <Route path="/solution" element={ <Navigate to='/solutions' /> } />

                <Route path="/jobs" element={ <Jobs /> } />
                <Route path="/job" element={ <Navigate to='/jobs' /> } />

                <Route path="/contact-us" element={ <ContactUs /> } />
                <Route path="/contact" element={ <Navigate to='/contact-us' /> } />

                <Route path="/privacy" element={ <Privacy /> } />
                <Route path="/cookies" element={ <Cookies /> } />
                <Route path="/terms-and-condition" element={ <TnCs /> } />
                <Route path="/tncs" element={ <Navigate to='/terms-and-condition' /> } />
                {/* AUTH ROUTES END */}

            </Routes>

        </>
     );
}



 
export default AllRoutes;