import { useState } from "react";
import styled from "styled-components";

import HeroSliderV1 from "library/heroslider/HeroSliderV1";

const PageWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: auto;
display: flex;
position: relative;

.hero-area {
    margin: 0 auto;
    width: 100%;
    height: 80vh;
    display: flex;
    position: relative;
}
`;

const Welcome = () => {

    const [ slideData, setSlideData ] = useState([
        {
        image: 'hero-v1/xpo-office-15-1.jpg',  
        title: "Find & Support Small Businesses around you.",
        description: "Create, manage and use your brand assets on-the-go. Create Logo, business card, letterhead, resume, brand book, corporate profile, id card, profile card, portfolio, xpo office, email signature, pricelist, invoice, receipt, quote, corporate identity, videos" 
        },
        {
        image: 'hero-v1/xpo-office-16-1.jpg',
        title: "Expose your brand to more customers around the world. 24 / 7.",
        description: "Add your existing brand assets or create a new brand identity on BiznesXpo to generate all your branded business documents and use anywhere anytime"
        },
        {
        image: 'hero-v1/xpo-office-15-2.jpg',
        title: "Find & Network with Top Professionals. Anytime. Anywhere.",
        description: "Use openai's chatgpt to generate copy right into your brand assets. From company name to proposal or contract copy generation, let openai and our design system ai work to put your brand ahead of its competition"
        },
        
      ]);
    

    return (    
            <PageWrapper>
                <div className="hero-area">
                <HeroSliderV1 data={ [ ...slideData ] } maxTransSlides={ 5 } />
                </div>
                <div className="page-content__main"></div>
            </PageWrapper>
        

     );
}
 
export default Welcome;